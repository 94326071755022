<template>
  <Modal :style="computedWindowSize"
         :visible="visible"
         class="p-fluid"
         @close="close">
    <template v-slot:header>
      <div class="p-dialog-title">Reduced position editing</div>
    </template>
    <Spinner v-if="dataIsSending"></Spinner>
    <div v-else class="p-formgrid p-grid p-pt-3">
      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12 p-mb-5">
        <div class="p-float-label">
          <InputText id="reduced_position_code_modal" disabled v-model="itemData.code" autocomplete="new-password"/>
          <label for="reduced_position_code_modal">{{ $t('Code') }}</label>
        </div>
      </div>
      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12 p-mb-5">
        <div class="p-float-label">
          <InputText id="reduced_position_name_modal" disabled v-model="itemData.name" autocomplete="new-password"/>
          <label for="reduced_position_name_modal">{{ $t('Name') }}</label>
        </div>
      </div>
      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12 p-mb-5" v-show="!fromPurchaseInvoice">
        <div class="p-float-label">
          <CustomDropdown id="reduced_position_type_modal"
                    :class="{'p-invalid' : submitted && (!fromPurchaseInvoice && !selectedType)}"
                    v-model="selectedType"
                    :preventFilter="true"
                    :options="ReducedPositionTypes">
            <template #value="slotProps">
              <div v-if="slotProps.value">
                <span>{{ slotProps.value.label[$i18n.locale] }}</span>
              </div>
              <span v-else style="visibility: hidden">.</span>
            </template>
            <template #option="slotProps">
              <div>
                <span>{{ slotProps.option.label[$i18n.locale] }}</span>
              </div>
            </template>
          </CustomDropdown>
          <label for="reduced_position_type_modal">{{ $t('Type') }}<span class="warning-color">*</span></label>
        </div>
        <small class="p-invalid" v-if="submitted && (!fromPurchaseInvoice && !selectedType)">{{ $t('Required field') }}</small>
      </div>

      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12 p-mb-5" v-show="!fromPurchaseInvoice">
        <div class="p-float-label">
          <CustomDropdown id="reduced_position_reason_modal"
                          :class="{'p-invalid' : submitted && (!fromPurchaseInvoice && !selectedReason)}"
                          v-model="selectedReason"
                          :preventFilter="true"
                          :options="ReducedPositionReasons">
            <template #value="slotProps">
              <div v-if="slotProps.value">
                <span>{{ slotProps.value.label[$i18n.locale] }}</span>
              </div>
              <span v-else style="visibility: hidden">.</span>
            </template>
            <template #option="slotProps">
              <div>
                <span>{{ slotProps.option.label[$i18n.locale] }}</span>
              </div>
            </template>
          </CustomDropdown>
          <label for="reduced_position_reason_modal">{{ $t('Reason') }}<span class="warning-color">*</span></label>
        </div>
        <small class="p-invalid" v-if="submitted && (!fromPurchaseInvoice && !selectedReason)">{{ $t('Required field') }}</small>
      </div>

      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12">
<!--        <div class="p-float-label p-input-icon-right">-->
<!--          <i class="ti-comment-alt" />-->
<!--          <Textarea id="reduced_position_comment_modal" v-model="itemData.comment" rows="3" :autoResize="true" autocomplete="new-password"/>-->
<!--          <label for="reduced_position_comment_modal">{{ $t('Comment') }}</label>-->
<!--        </div>-->
        <LimitedCharsTextarea :submitted="submitted"
                              v-model="itemData.comment"
                              :maxChars="commentMaxChars"
                              :id="'reduced-position-comment'"
                              :label="$t('Comment')"/>
      </div>
    </div>

    <template #footer>
      <Button :label="$t('Cancel')" icon="pi pi-times" class="p-button-text" @click="close"/>
      <Button :label="$t('Save')" :disabled="disableSaveButton" icon="pi pi-check" class="p-button-text" @click="saveItem" />
    </template>
  </Modal>
</template>

<script>
import httpClient from "@/services/http.services";
import httpMixins from "@/mixins/httpMixins";
import constants from "@/constants";
import formatMixins from "@/mixins/formatMixins";
import settings from "@/settings";
import ReducedPositionTypes from "@/translations/ReducedPositionTypes";
import ReducedPositionReasons from "@/translations/ReducedPositionReasons";

export default {
  mixins: [ httpMixins, formatMixins ],
  emits: ['close', 'update-items'],
  name: 'ReducedPositionModal',
  props: {
    item: Object,
    visible: Boolean,
  },
  data() {
    return {
      commentMaxChars: 240,
      dataIsSending: false,
      disableSaveButton: false,
      selectedType: null,
      selectedReason: null,
      fromPurchaseInvoice: false,
      itemData: {},
      submitted: false,
      constants,
      settings,
      ReducedPositionTypes,
      ReducedPositionReasons
    }
  },
  watch: {
    item(value) {
      this.itemData = {
        ...value,
        code: value.position_data?.code,
        name: value.position_data?.name,
      }

      this.selectedType = value.type ? ReducedPositionTypes.find((item) => item.id === value.type) : null
      this.selectedReason = value.reason ? ReducedPositionReasons.find((item) => item.id === value.reason) : null

      if (value.purchase_invoice_position_id) {
        this.fromPurchaseInvoice = true
      } else {
        this.fromPurchaseInvoice = false
      }
    },
    visible() {
      if (!this.visible) {
        this.disableSaveButton = false
        this.dataIsSending = false
        this.submitted = false
        this.selectedType = null
        this.selectedReason = null
        this.fromPurchaseInvoice = false
      }
    },
  },
  methods: {
    async saveItem() {
      this.submitted = true
      if (!this.fromPurchaseInvoice && (!this.selectedType || !this.selectedReason) ||
          this.itemData.comment && this.itemData.comment.length > this.commentMaxChars) return false

      this.disableSaveButton = true
      this.dataIsSending = true

      const reducedPosition = {
        id: this.itemData.id ?? null,
        type: this.selectedType.id ?? null,
        reason: this.fromPurchaseInvoice ? null : this.selectedReason.id ,
        comment: this.itemData.comment,
      }

      try {
        const { status, data } = await httpClient.post(`reduced-position/update`, reducedPosition)
        if (status === 200 && data?.success) {
          this.$emit('update-items', this.itemData.id)
          // this.$emit('update-item', reducedPosition)
          this.$toast.add({severity:'success', detail: this.$t('Data saved'), life: this.settings.toastLife});
          this.close()
        }
      } catch(err) {
        this.showError(err)
      } finally {
        this.dataIsSending = false
        this.disableSaveButton = false
      }
    },
    close() {
      this.$emit('close')
      this.submitted = false
    },
  },
  computed: {
    computedWindowSize() {
      if (this.$store.state.mobileLayout) {
        return {position: 'absolute', top: 0, width: '95%', marginTop: '10px'}
      } else if (!this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
        return {position: 'absolute', top: 0, width: '550px', marginTop: '15px'}
      } else {
        return {position: 'absolute', top: 0, width: '550px'}
      }
    },
  },

}
</script>